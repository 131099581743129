<h2 mat-dialog-title>Offer</h2>
<mat-dialog-content>
    <div
            class="relative min-w-[400px] h-[400px] flex flex-col overflow-auto text-gray-700 dark:text-white bg-white dark:bg-[#303030] border rounded-xl bg-clip-border">
        <table class="text-left table-fixed" [formGroup]="form">
            <tbody>
            <tr class="bg-[#F9FAFB] dark:bg-[#303030]">
                <th class="p-4 border-b border-blue-gray-100 bg-blue-gray-50">
                    Package:
                </th>
                <th colspan="2" class="p-4 border-b border-blue-gray-100 bg-blue-gray-50">
                    <div class="flex gap-2 flex-col xl:flex-row">
                        @for (control of form.controls.packagesId.controls; track $index) {
                            <mat-form-field class="flex-1" subscriptSizing="dynamic">
                                <mat-label>Select {{ $first ? 'start' : 'recover' }} package</mat-label>
                                <mat-select [formControl]="control">
                                    @for (offerPackage of [offerPackageItems1(), offerPackageItems2()][$index]; track $index) {
                                        <mat-option [value]="offerPackage.id">{{ offerPackage.name }}</mat-option>
                                    } @empty {
                                        <mat-option disabled>Empty packages</mat-option>
                                    }
                                </mat-select>
                                <button matSuffix mat-icon-button
                                        (click)="addOfferPackage(control, $first ? 'START' : 'RECOVER'); $event.stopPropagation()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-form-field>
                        }
                    </div>
                </th>
            </tr>
                @if (package1() || package2()) {
                    <tr>
                        <td class="p-4 border-b border-blue-gray-50">
                            Initial Payment
                        </td>
                        <td class="p-4 border-b border-blue-gray-50">
                            {{ package1()?.initialPaymentString ?? '---' }}
                        </td>
                        <td class="p-4 border-b border-blue-gray-50">
                            {{ package2()?.initialPaymentString ?? '---' }}
                        </td>
                    </tr>
                    <tr>
                        <td class="p-4 border-b border-blue-gray-50">
                            Commission after Blocking
                        </td>
                        <td class="p-4 border-b border-blue-gray-50">
                            {{ package1()?.postBlockingFeeString ?? '---' }} {{ package1()?.isBlockingPercent ? '%' : '' }}
                        </td>
                        <td class="p-4 border-b border-blue-gray-50">
                            {{ package2()?.postBlockingFeeString ?? '---' }} {{ package2()?.isBlockingPercent ? '%' : '' }}
                        </td>
                    </tr>
                    <tr>
                        <td class="p-4 border-b border-blue-gray-50">
                            Commission after Recovery
                        </td>
                        <td class="p-4 border-b border-blue-gray-50">
                            {{ package1()?.postRecoveryFeeString ?? '---' }} {{ package1()?.isRecoveryPercent ? '%' : '' }}
                        </td>
                        <td class="p-4 border-b border-blue-gray-50">
                            {{ package2()?.postRecoveryFeeString ?? '---' }} {{ package1()?.isRecoveryPercent ? '%' : '' }}
                        </td>
                    </tr>
                }
            <tr>
                <td class="p-4 border-b border-blue-gray-50">
                    Time estimate:
                    <br><br>
                    {{ form.controls.expiredAt.getRawValue() | date: 'hh:mm a, dd/MM/yyyy' }}
                </td>
                <td colspan="2" class="p-4 border-b border-blue-gray-50" [formGroup]="formExpiredAt">
                    <div class="flex gap-2 flex-wrap">
                        <mat-form-field class="w-[100px]">
                            <mat-label>Days</mat-label>
                            <input formControlName="days" type="number" [min]="0" matInput>
                        </mat-form-field>
                        <mat-form-field class="w-[100px]">
                            <mat-label>Hours</mat-label>
                            <input formControlName="hours" type="number" [min]="0" matInput>
                        </mat-form-field>
                        <mat-form-field class="w-[100px]">
                            <mat-label>Minutes</mat-label>
                            <input formControlName="minutes" type="number" [min]="0" matInput>
                        </mat-form-field>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="p-4 border-b border-blue-gray-50">
                    Probability of recovery
                </td>
                <td colspan="2" class="p-4 border-b border-blue-gray-50">
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-label>Select</mat-label>
                        <mat-select formControlName="recoveryProbability">
                            @for (item of recoveryProbabilityItems; track $index) {
                                <mat-option [value]="item.value">{{ item.name }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td colspan="3" class="p-4 border-b border-blue-gray-50">
                    Photo:
                </td>
            </tr>
            <tr>
                <td colspan="3" class="p-4 border-b border-blue-gray-50">
                    <div class="w-[160px] h-[100px]">
                        <app-image-uploader [formControlRef]="form.controls.photo"></app-image-uploader>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="3" class="p-4 border-b border-blue-gray-50">
                    Solution<span class="text-red-500">*</span>:
                </td>
            </tr>
            <tr>
                <td colspan="3" class="p-4 border-b border-blue-gray-50">
                    <div class="NgxEditor__Wrapper">
                        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                        <ngx-editor [editor]="editor" formControlName="description" [disabled]="false"
                                    [placeholder]="'Type here...'"></ngx-editor>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close cdkFocusInitial color="warn">Close</button>
    <button mat-flat-button color="accent" (click)="beforeSubmit()" [disabled]="form.invalid || formExpiredAt.invalid">
        Add
    </button>
</mat-dialog-actions>

@let control = formControlRef();
@if (control.getRawValue(); as file) {
    @if (previewURL(); as base64) {
        <div
                class="relative overflow-hidden w-full h-full border-[1.5px] border-solid border-yellow-500 rounded-[8px]"
        >
            <button (click)="delete()" type="button" class="delete-icon">
                <mat-icon class="icon">delete</mat-icon>
            </button>

            <img (click)="openFullImage(base64)" class="object-cover w-full h-full" [src]="base64"
                 alt="Uploaded image"/>
        </div>
    }
} @else {
    <label
            class="text-[14px] text-[#797979] overflow-hidden w-full h-full inline-flex flex-col justify-center items-center gap-2 bg-[#F6F6FA] border-[2px] border-dashed border-[#E8E9F2] rounded-[8px] relative"
    >
        <input
                class="w-0 h-0 overflow-hidden"
                type="file"
                (change)="onFileSelected($event)"
                accept="image/svg+xml, image/png, image/jpeg"
        />
        <!--accept="image/*"-->

        @if (isLoading()) {
            <mat-spinner></mat-spinner>
        } @else {
            <mat-icon color="accent">add</mat-icon>
            @if (placeholder(); as placeholder) {
                <div class="text-ellipsis overflow-hidden whitespace-nowrap">
                    {{ placeholder }}
                </div>
            }
        }
    </label>
}
